import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';
import CTAComponent from '../components/cta';
import HollowButton from '../components/buttons/HollowButton';

const Content = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 110px;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        margin-top: 40px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 130px;
    }
`;
const ItemContainer = styled.div`
    width: calc(25% - 24px);

    border-radius: 8px;
    background: url(${props => props.img}) no-repeat center;
    background-color: #322f33;
    background-size: cover;
    margin-bottom: 32px;
    display: flex;
    height: 320px;
    position: relative;
    @media screen and (max-width: 1200px) {
        width: calc(50% - 16px);
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 16px;
    }
`;
const Overlay = styled.div`
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 0;
    ${ItemContainer}:hover > & {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;
const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: auto;
    width: 100%;
`;
const TextContainer = styled.div`
    font-size: 20px;
    line-height: 32px;
    color: white;
    letter-spacing: -0.5px;
    font-family: 'Renogare-Regular';
    text-align: center;
    width: 200px;
`;
const ItemFooter = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 72px;
    margin-top: auto;
`;
const ItemContent = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
`;
const Item = ({ url, background_image, title, button_title, image }) => {
    return (
        <ItemContainer img={background_image}>
            <Overlay></Overlay>
            <ItemContent>
                <ImgContainer>
                    {!!title ? (
                        <TextContainer>{title}</TextContainer>
                    ) : (
                        <img
                            alt=""
                            src={image}
                            width="200px"
                            style={{ objectFit: 'contain' }}
                        ></img>
                    )}
                </ImgContainer>
                <ItemFooter>
                    <HollowButton url={url}>{button_title}</HollowButton>
                </ItemFooter>
            </ItemContent>
        </ItemContainer>
    );
};
export default function AdvertisersPage({ data }) {
    const { hero, category, book_now, seo_content } = data.butterPage;

    return (
        <Layout menu="FOR ADVERTISERS">
            <SEOComponent
                title="For Advertisers"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]} type={1} back={'dark'}></Hero4>
            <Content>
                {category.map(item => {
                    return <Item {...item} key={item.url} />;
                })}
            </Content>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const advertisersPageQuery = graphql`
    query {
        butterPage(slug: { eq: "advertisers" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                description
                desktop_image
                mobile_image
                mask_image
            }
            category {
                title
                image
                background_image
                url
                button_title
            }
            book_now {
                button_title
                title
            }
        }
    }
`;
